<template>
  <!-- <h1>This is an about page</h1> -->
  <div class="login">
    <outer-ip></outer-ip>
    <div class="back"></div>
    <!-- <div class="tit">
      <img src="../../../public/bank_logo.png" />
      <div class="titsize">行长甄选--权益管理系统后台</div>
    </div> -->
	<!-- <div class="presidentLogo">
		<img src="../../../public/presidentLogo.png" alt="">
	</div> -->
    <div class="form">
      <div class="formTitle">欢迎登录</div>
      <div class="menu">
        <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="60px"
          class="demo-ruleForm"
        >
          <el-form-item style="font-weight: bold" label="账号" prop="account">
            <el-input
              style="width: 230px; float: left; background-color: white"
              v-model.number="ruleForm.account"
            ></el-input>
          </el-form-item>
          <el-form-item style="font-weight: bold" label="密码" prop="pass">
            <el-input
              style="width: 230px; float: left"
              type="password"
              v-model="ruleForm.pass"
              autocomplete="off"
              @keyup.enter.native="submitForm('ruleForm')"
            ></el-input>
          </el-form-item>
          <el-form-item class="btn">
            <el-button
              type="primary"
              style="border: 1px solid red; background-color: red; border: none"
              @click="submitForm('ruleForm')"
              v-loading.fullscreen.lock="fullscreenLoading"
              >登录</el-button
            >
            <el-button
              style="border: 1px solid red; color: red"
              @click="resetForm('ruleForm')"
              >重置</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div class="copy">Copyright©小码旺铺.All rights reserved.</div>
  </div>
</template>

<script>
import outerIp from "../../components/commodity/ipaddress.vue";
import browserType from "../../utils/model.js";
export default {
  name: "LoginFrom",
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("账号不能为空"));
      }
      setTimeout(() => {
        if (value.length < 1) {
          callback(new Error("请输入账号"));
        } else {
          callback();
        }
      }, 1000);
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };

    return {
      fullscreenLoading: false,
      ruleForm: {
        pass: "",
        checkPass: "",
        account: "",
      },
      rules: {
        pass: [
          {
            validator: validatePass,
            trigger: "blur",
          },
        ],
        account: [
          {
            validator: checkAge,
            trigger: "blur",
          },
        ],
      },
      brower: "",
    };
  },
  components: {
    outerIp,
  },
  methods: {
    submitForm(formName) {
      let ip = returnCitySN["cip"];
      this.brower = browserType.model();
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$request
            .Login({
              name: this.ruleForm.account,
              pass: this.ruleForm.pass,
              ip: ip,
              brow: this.brower,
            })
            .then((res) => {
              if (res.code == 0) {
                this.$message({
                  showClose: true,
                  message: "登陆成功",
                  type: "success",
                });
                this.$storage.setLocal(res.data.token, "token");
                this.$storage.setLocal(res.data.admin_type, "type");
                this.$storage.setLocal(res.data.login_name, "login_name");
                this.$router.replace(
                  {
                    path: "/index",
                  },
                  function () {},
                  function () {}
                );
              } else {
                this.$message({
                  showClose: true,
                  message: "账号或者密码错误",
                  type: "error",
                });
              }
            });
        } else {
          this.$message({
            showClose: true,
            message: "请输入账号或密码",
            type: "error",
          });
          return false;
        }
      });
    },
    resetForm(formName) {
      //重置
      this.$refs[formName].resetFields();
    },
  },
  created() {},
};
</script>

<style scoped="scoped">
.presidentLogo{
	width: 90px;
	height: 90px;
	margin-top: 60px;
	margin-left: 80px;
}
.copy {
  /* border: 1px solid black; */
  position: absolute;
  bottom: 25px;
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #ffffff;
}
.titsize {
  color: white;
  font-size: 30px;
}
.tit {
  position: absolute;
  margin-left: 166px;
  margin-top: 116px;
}

.btn {
  margin-top: 100px;
  margin-right: 50px;
}

.menu {
  margin-top: 80px;
  width: 100%;
  color: black;
}

.formTitle {
  /* color: #D9001B; */
  font-size: 30px;
  font-weight: 800;
  margin-top: 20px;
}

.login {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* background-color: #FFFFFF; */
  background-image: url(../../../public/bck.png);
  background-size: 100% 100%;
}

.login .back {
  /* background-color: #D9001B; */
  width: 100%;
  height: 300px;
  position: absolute;
  left: 50%;
  top: 0%;
  transform: translate(-50%, 100%);
}

.form {
  border: 1px solid #e8e8e8;
  /* border-radius: 10px; */
  background-color: #f9f8f8d4;
  /* opacity: 0.9; */
  width: 330px;
  height: 450px;
  position: absolute;
  left: 65%;
  top: 0%;
  transform: translate(-10%, 30%);
  /* box-shadow:1px 1px 1px 1px #E8E8E8; */
  /* box-shadow:-1px -1px -1px 1px #E8E8E8; */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2), 0px -2px 10px rgba(0, 0, 0, 0.2);
  /* box-shadow: ; */
}
</style>
